import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet-async";
import './ContactUs.css';

function ContactUs() {
    const [contactContent, setContactContent] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleChange = (e) => {
        const sanitizedValue = sanitizeInput(e.target.value);
        setFormData({
            ...formData,
            [e.target.name]: sanitizedValue
        });
    };

    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {e.preventDefault();}
        console.log('In handleSubmit');
        const validationError = validateInput(formData);
        if (validationError) {
            alert(validationError);
            return;
        }
        try {
            const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    //'Referrer-Policy': 'strict-origin-when-cross-origin'
                }
            });
            console.log('SUCCESS!', response.data);
            alert('Your message has been sent successfully!');
            setFormData({
                name: '',
                email: '',
                phoneNumber: '',
                message: ''
            });
        } catch (error) {
            console.error('FAILED....', error);
            alert('Failed to send message, please try again.');
        } 
        console.log(formData);
    };

    useEffect(() => {
        fetch('content/contactUs/contactUsContent.html').then((response) => response.text()).then((html) => setContactContent(html));
    },[]);

    return (
        <div className="container mt-5">
            <Helmet>
                <title>Contact Us - BlueSurf Construction Pty Ltd</title>
                <meta name="description" content="Welcome to BlueSurf Construction Pty Ltd. We offers top-notch construction services." />
                <meta name="keywords" content="Construction, Bulding, Services, Projects, Renovations, Contact" />
            </Helmet>
            <h1>Contact Us</h1>
            <div className="container mt-4" dangerouslySetInnerHTML={{__html: contactContent}}></div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Name</label>
                    <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    {/* <label>Email </label> */}
                    <span><FontAwesomeIcon icon={faEnvelope} className="email-icon"/></span>
                    <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    {/* <label>Phone Number: </label> */}
                    <span><FontAwesomeIcon icon={faPhone} className="phone-icon"/></span>
                    <input type="text" name="phoneNumber" className="form-control" value={formData.phoneNumber} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Message</label>
                    <textarea name="message" className="form-control" value={formData.message} onChange={handleChange} required></textarea>
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );

    function sanitizeInput(input) {
        return DOMPurify.sanitize(input);
    }

    function validateInput(formData) {
        const { name, email, phoneNumber, message} = formData;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegx = /^[0-9\-+]{9,15}$/;

        if (name.lengh < 1 || name.lengh > 50) return "Name must be between 1 and 50 characters.";
        if (!emailRegex.test(email)) return "Invalid email address.";
        if (!phoneRegx.test(phoneNumber)) return "Phone number must be between 9 and 15 digits";
        if (message.lengh < 1 || message.lengh > 500) return "Message must be max of 500 characters.";

        return null;
    }

}

export default ContactUs;