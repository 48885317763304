import React from "react";
import { Navbar,  Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../../assets/logo/BlueSurfLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import Menu from "../Menu/Menu";
import './Header2.css';
import PhoneLine from "../Phone/PhoneLine";
import EmailAddress from "../Email/EmailAddress";

function Header() {
    return (
        <header>
            <div className="top-bar">
                <Container>
                    <div className="top-bar-content">
                        <div className="contact-info">
                            <span><EmailAddress /></span>
                            <span> <PhoneLine /></span>
                        </div>
                        <div className="social-media">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faXTwitter} size="2x" />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <Navbar expand="lg" className="main-navbar">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img src={Logo} alt="BlusSurf-Logo" className="navbar-brand-img" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Menu />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;