import React, { useState, useEffect } from "react";
import ContactContent from "../ContactContent/ContactContent";
import './Footer.css';
import QuicLinks from "../QuickLinks/QuickLinks";


function Footer() {
  const [disclaimer, setDisclamer] = useState('');
  // const [quickLinks, setQuickLinks] = useState('');
  const [followUs, setFollowUs] = useState('');
  
   useEffect(() => {
    
    fetch('content/footer/disclaimer.html').then((response) => response.text()).then((html) => {
      const currentYear = new Date().getFullYear();
      const updateHtml = html.replace('{year}', currentYear);
      setDisclamer(updateHtml);
    });

    // fetch('content/footer/quickLinks.html').then((response) => response.text()).then((html) => setQuickLinks(html));

    fetch('content/footer/followUs.html').then((response) => response.text()).then((html) => setFollowUs(html));

  }, []);

  return (
    <footer className="footer-container">
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-column">
            <ContactContent />
          </div>
          {/* <div className="footer-column quick-links" dangerouslySetInnerHTML={{__html: quickLinks}}></div> */}
          <QuicLinks />
          <div className="footer-column social-media" dangerouslySetInnerHTML={{__html: followUs}}></div>
        </div>
        <div className="footer-bottom" dangerouslySetInnerHTML={{__html: disclaimer}}>      
        </div>
      </div>
    </footer>
  );
}

export default Footer;

