import React, { useState, useEffect } from "react";
import CarouselComponent from '../../components/Carousel/CarouselComponent';
import { Helmet } from "react-helmet-async";
import './Home.css';


function Home() {

    const [content, setContent] = useState('');
    const [contentBottom, setContentBottom] = useState('');
 
    useEffect(() => {
        fetch('content/home/homePageContent.html').then((response) => response.text()).then((html) => setContent(html));
    }, []);

    useEffect(() => {
        fetch('content/home/homeContentBottom.html').then((response) => response.text()).then((html) => setContentBottom(html));
    },[]);

    return (
        <div className="home">
            <Helmet>
                <title>Home - BlueSurf Construction Pty Ltd</title>
                <meta name="description" content="Welcome to BlueSurf Construction Pty Ltd. We offers top-notch construction services." />
                <meta name="keywords" content="Construction, Bulding, Services, Projects, Renovations, Contact" />
            </Helmet>
            <div className="welcome-section">
                <h1> Welcome to BlueSurf Construction</h1>
            </div>
            <div className="description-section">
                <div className="container mt-4" dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
            <div className="carousel-section">
                <CarouselComponent />
            </div>
            <div className="description-section">
                <div className="container mt-4" dangerouslySetInnerHTML={{__html: contentBottom}}/>
            </div>
        </div>

    );
}

export default Home;