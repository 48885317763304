import React, { useState, useEffect } from "react";

function EmailAddress() {
    const [email, setEmail] = useState('');

    useEffect(() => {
        fetch('content/contactUs/email.html').then((response) => response.text()).then((html) => setEmail(html));
    }, []);

    return (
        <div className="container" dangerouslySetInnerHTML={{ __html: email }}></div>
    );
}

export default EmailAddress;