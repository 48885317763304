import React from "react";
import { Carousel } from "react-bootstrap";
import CarouselImageJson from '../../assets/images/CarouselImage.json';
import CarouselImages from '../../utils/importAllImagJson.js';


const imageImports = CarouselImages(require.context('../../assets/images', false, /\.(png|jp?g|svg)$/), CarouselImageJson);

function CarouselComponent() {

    return (
        <Carousel>
            {CarouselImageJson.map((image, index) => (
                <Carousel.Item key={index}>
                    <img className="d-block w-100" src={imageImports[image.src]} alt={image.alt} />
                    <Carousel.Caption>
                        <h3>{image.caption}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default CarouselComponent;