import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from "./components/Header/Header";
import Home from "./containers/Home/Home";
import Gallery from "./pages/Gallery/Gallery";
import ContactUs from "./pages/Contact/ContactUs";
import AboutUs from "./pages/About/AboutUs";
import Services from "./pages/Services/Services";
import Footer from "./components/Footer/Footer";
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>BlueSurf Construction Pty Ltd</title>
          <meta name="description" content="BlueSurf Construction Pty Ltd. We offers top-notch construction services." />
          <meta name="keywords" content="Construction, Bulding, Services, Projects, Renovations, Contact" />
        </Helmet>
        <div>
          <Header />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/services" element={<Services />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
