import React, {useState, useEffect} from "react";
import aboutUsImage from '../../assets/images/AboutUs.json';
import './AboutUs.css';
import importImage from '../../utils/importAllImages.js';
import { Helmet } from "react-helmet-async";

const image = importImage(require.context('../../assets/images', false, /\.(png|jp?g|svg)$/), aboutUsImage);

function AboutUs() {
    const [aboutusContent, setAboutusContent] = useState('');
    const imageKeys = Object.keys(image);

    useEffect(() => {
        fetch('content/aboutUs/aboutUsContent.html').then((response) => response.text()).then((html) => setAboutusContent(html));
    },[]);

    return (
        <div className="about-us-container">
            <Helmet>
                <title>About Us - BlueSurf Construction Pty Ltd</title>
                <meta name="description" content="Learn more about BlueSurf Construction Pty Ltd, renowned for pushing the boundaries of construction projects." />
                <meta name="keywords" content="About BlueSurf Construction, Construction Services, Quality Construction, Brisbane, Renovations, Projects" />
            </Helmet>
            <div className="about-us-title">About Us</div>
            <div className="about-us-content">
            <div className="text-content">
                    <div className="container mt-4" dangerouslySetInnerHTML={{__html: aboutusContent}}></div>
            </div>
            <div className="image-content">
                {imageKeys.map((imageKey, index) => (
                    <div key={index} className="gallery-item">
                        <img src={image[imageKey]} alt="AboutUs-side" />
                    </div>
                ))}
            </div>
            </div>
        </div>
        
    );
}

export default AboutUs;