const importAllImages = (context, imageList) => {
    let images = {};
    imageList.forEach(imageName => {
        images[imageName] = context(`./${imageName}`);
    });
    return images;
}

export default importAllImages;

