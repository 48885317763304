import React, {useState, useEffect} from "react";
import './Gallery.css';
import imagesJson from '../../assets/images/images.json';
import importAllImages from "../../utils/importAllImagJson.js";
import { Helmet } from "react-helmet-async";

const imageImports = importAllImages(require.context('../../assets/images', false, /\.(png|jp?g|svg)$/), imagesJson);

function Gallery() {
    const [galleryContent, setGalleryContent] = useState('');
    
    useEffect(() => {
        fetch('content/gallery/galleryContent.html').then((response) => response.text()).then((html) => setGalleryContent(html));
    },[]);
    
    return (
        <div className="gallery-container">
            <Helmet>
                <title>Gallery - BlueSurf Construction Pty Ltd</title>
                <meta name="description" content="Welcome to BlueSurf Construction Pty Ltd. We offers top-notch construction services." />
                <meta name="keywords" content="Construction, Bulding, Services, Projects, Renovations, Contact" />
            </Helmet>
            <div className="gallery-title"><h1>Our Projects</h1></div>
            <div className="gallery-content">
                <div className="text-content">
                    <div className="container mt-4" dangerouslySetInnerHTML={{__html: galleryContent}}></div>
                </div>
                <div className="gallery">
                    {imagesJson.map((imageKey, index) => (
                        <div key={index} className="gallery-item">
                            <img src={imageImports[imageKey.src]} alt={imageKey.alt} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Gallery;