import React from "react";
import PhoneLine from "../Phone/PhoneLine";
import EmailAddress from "../Email/EmailAddress";
import Address from "../Address/Address";

function ContactContent() {

    return (
        <div>
            <Address />
            <PhoneLine />
            <EmailAddress />
        </div>
    );
}

export default ContactContent;