import React, {useState, useEffect} from "react";


function PhoneLine () {
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        fetch('content/contactUs/phone.html').then((response) => response.text()).then((html) => setPhoneNumber(html));
    },[]);

    return(
        <div className="container" dangerouslySetInnerHTML={{__html: phoneNumber}}></div>
    );
}

export default PhoneLine;