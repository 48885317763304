import React from "react";
import { NavLink } from "react-router-dom";
import './QuickLinks.css';

function QuicLinks() {
    return (
        <div className="quick-links">
            <h4>Quick Links</h4>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about">About Us</NavLink></li>
                <li><NavLink to="/services">Services</NavLink></li>
                <li><NavLink to="/gallery">Gallery</NavLink></li>
                <li><NavLink to="/contact">Contact Us</NavLink></li>
            </ul>
        </div>
    );
}

export default QuicLinks;