import React, {useState, useEffect} from "react";

function Address() {
    const [address, setAddress] = useState('');

    useEffect(() => {
        fetch('content/contactUs/address.html').then((response) => response.text()).then((html) => setAddress(html));
    },[]);

    return (
        <div className="container" dangerouslySetInnerHTML={{__html: address}}></div>
    );
}

export default Address;