import React, { useState, useEffect } from "react";
import servicesImage from '../../assets/images/ServicePage.json';
import importServicesImage from "../../utils/importAllImages";
import { Helmet } from "react-helmet-async";
import './Services.css';

const image = importServicesImage(require.context('../../assets/images', false, /\.(png|jp?g|svg)$/), servicesImage);

function Services() {
    const [servicesContent, setServicesContent] = useState('');
    const imageKeys = Object.keys(image);

    useEffect(() => {
        fetch('content/services/servicesContent.html').then((response) => response.text().then((html) => setServicesContent(html)));
    }, []);
    return (
        <div className="services-container">
            <Helmet>
                <title>Services - BlueSurf Construction Pty Ltd</title>
                <meta name="description" content="Welcome to BlueSurf Construction Pty Ltd. We offers top-notch construction services." />
                <meta name="keywords" content="Construction, Bulding, Services, Projects, Renovations, Contact" />
            </Helmet>
            <div className="services-title">Services</div>
            <div className="services-content">
                <div className="text-content">
                    <div className="container mt-4" dangerouslySetInnerHTML={{ __html: servicesContent }}></div>
                </div>
                <div className="image-content">
                    {imageKeys.map((imageKey, index) => (
                        <div key={index} className="gallery-item">
                            <img src={image[imageKey]} alt="ServicePage-Side" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Services;